import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  useCallback,
  useMemo
} from 'react';
import { TextField as MuiTextField, styled } from '@mui/material';
import Control from './TextFieldControl';
import CountHelperText from './TextFieldCountHelperText';
import FieldLabel from '../FieldLabel';

type Props = {
  label?: string;
  value?: string;
  focused?: boolean;
  placeholder?: string;
  width?: number;
  maxLength?: number;
  rows?: number;
  maxNewLines?: number;
  helperText?: string;
  hasLengthHelperText?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
};

/**
 * テキストフィールドを返す
 * @param {string | undefined} label ラベル文言
 * @param {string | undefined} value 入力値
 * @param {boolean | undefined} focused フォーカス状態であるか(プレースホルダーをデフォルトで表示するか)
 * @param {string | undefined} placeholder プレースホルダ
 * @param {number | undefined} width フィールド幅
 * @param {number | undefined} maxLength 最大入力文字数
 * @param {number | undefined} rows 入力行数
 * @param {number | undefined} maxNewLines 最大改行数
 * @param {string | undefined} helperText ヘルパーテキスト
 * @param {boolean | undefined} hasLengthHelperText 文字数ヘルパーテキストを表示するか
 * @param {boolean | undefined} isRequired 入力が必須であるか
 * @param {boolean | undefined} isDisabled 入力が無効であるか
 * @param {boolean | undefined} isError エラーがあるか
 * @param {FocusEventHandler<HTMLTextAreaElement> | undefined} onBlur フォーカスが外れた際に実行される関数
 * @param {ChangeEventHandler<HTMLTextAreaElement> | undefined} onChange 値が変更された際に実行される関数
 * @returns テキストフィールド
 */
const TextField: FC<Props> = ({
  label = '',
  value = '',
  focused = true,
  placeholder = '',
  width = 0,
  maxLength = 999,
  rows = 1,
  maxNewLines = 0,
  helperText = '',
  hasLengthHelperText = false,
  isRequired = false,
  isDisabled = false,
  isError = false,
  onBlur,
  onChange
}) => {
  const lengthWithoutNewLines = useMemo(
    () => value.replace(/\n/g, '').length,
    [value]
  );

  const getValueWithoutExcessNewLines = useCallback(
    (value: string) => {
      if (maxNewLines <= 0) return value;

      const splitValue = value.split('\n');
      if (splitValue.length <= maxNewLines) return value;

      const excessNewLines = splitValue.slice(maxNewLines);

      return (
        splitValue.slice(0, maxNewLines).join('\n') + excessNewLines.join('')
      );
    },
    [maxNewLines]
  );

  return (
    <Control disabled={isDisabled}>
      <StyledTextField
        focused={focused}
        label={label && <FieldLabel label={label} isRequired={isRequired} />}
        value={value}
        placeholder={placeholder}
        helperText={
          helperText ||
          (hasLengthHelperText && (
            <CountHelperText
              maxLength={maxLength}
              remainingLength={maxLength - lengthWithoutNewLines}
              overLength={lengthWithoutNewLines - maxLength}
              isError={lengthWithoutNewLines > maxLength}
            />
          ))
        }
        fullWidth={width <= 0}
        multiline={rows > 1}
        rows={rows}
        disabled={isDisabled}
        error={isError || lengthWithoutNewLines > maxLength}
        style={{ ...(width && { width }) }}
        onBlur={onBlur}
        onChange={(e) => {
          e.target.value = getValueWithoutExcessNewLines(e.target.value);
          onChange?.(e as ChangeEvent<HTMLTextAreaElement>);
        }}
      />
    </Control>
  );
};

export default TextField;

const StyledTextField = styled(MuiTextField)(() => ({
  '& .MuiOutlinedInput-root.MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1
    }
  }
}));
