import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MasterDataContext from 'context/saiyouPageEditing/MasterDataContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { useOccupationsValidationRules } from 'validation/rules/saiyouPageEditing';
import GroupingSelectBox from '../../../../common/SelectBox/GroupingSelectBox';

/**
 * 職種の選択フィールドを返す
 * @returns 職種の選択フィールド
 */
const OccupationsField: FC = () => {
  const { control } = useFormContext<FormData>();

  const { occupations } = useContext(MasterDataContext);

  const rules = useOccupationsValidationRules();

  return (
    <div className="input-content-wrap occupation">
      <div className="input-content-title-wrap">
        <span className="input-content-title">職種</span>
        <span className="input-content-title-sub">
          お仕事紹介やSEOに関する設定に使用するメインで募集する職種を選択してください。
        </span>
      </div>
      <Controller
        name="jobIntroduction.occupations"
        control={control}
        rules={rules}
        render={({
          field: { value, onBlur, onChange },
          formState: { errors }
        }) => (
          <GroupingSelectBox
            values={value.map((v) => v.code)}
            placeholder="選択してください"
            options={occupations}
            helperText={errors.jobIntroduction?.occupations?.message}
            width={685}
            isMultiple
            isRequired
            isError={!!errors.jobIntroduction?.occupations}
            onBlur={onBlur}
            onChange={(values) => {
              onChange(
                values.map((v) => ({
                  code: v as string
                }))
              );
            }}
            style={{ marginTop: '-10px' }}
          />
        )}
      />
    </div>
  );
};

export default OccupationsField;
