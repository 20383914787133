import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import ContentAcc from '../../../../../common/ContentAcc/ContentAcc';
import ContentAccHead from '../../../../../common/ContentAcc/ContentAccHead';
import RadioButtonsGroup from '../../../../../common/RadioButtonsGroup';
import FormDivider from '../../shared/FormDivider';
import TabContentFormWrap from '../../shared/TabContentFormWrap';
import TabContentInner from '../../shared/TabContentInner';
import TabContentText from '../../shared/TabContentText';
import JobIntroReorderBlocks from './JobIntroReorderBlocks';

const layoutOptions = [
  { label: 'デフォルト', value: 'default', defaultSelected: true },
  { label: 'カード', value: 'card' }
];

/**
 * 「お仕事紹介」アコーディオンを返す
 * @returns 「お仕事紹介」アコーディオン
 */
const JobIntroAccordion: FC = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('jobIntro');

  return (
    <ContentAcc
      ref={accordionRef}
      title="お仕事紹介"
      dispCrown
      isExpanded={isExpanded}
      isRequired
      isError={
        errors.jobIntroduction && Object.keys(errors.jobIntroduction).length > 0
      }
      onChange={(value) => onChange?.('jobIntro', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="応募者が働く姿をイメージしやすい画像や仕事内容を設定してください。" />
        <TabContentFormWrap>
          <ContentAccHead title="レイアウト" style={{ paddingTop: '10px' }} />
          <Controller
            name="jobIntroduction.layout"
            control={control}
            rules={{ onChange: onChangeValue }}
            render={({ field: { value, onChange } }) => (
              <RadioButtonsGroup
                options={layoutOptions}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <FormDivider />
          <JobIntroReorderBlocks />
        </TabContentFormWrap>
      </TabContentInner>
    </ContentAcc>
  );
};

export default JobIntroAccordion;
