/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PageSettingAboutJobDto
 */
export interface PageSettingAboutJobDto {
    /**
     * 小職種コード。区分値のバリデーションはかけない
     * @type {string}
     * @memberof PageSettingAboutJobDto
     */
    shok3Cd?: string;
    /**
     * 表示用職種名
     * @type {string}
     * @memberof PageSettingAboutJobDto
     */
    dispName?: string;
    /**
     * 職種紹介文 改行コードが含まれていれば取り除く
     * @type {string}
     * @memberof PageSettingAboutJobDto
     */
    text?: string;
    /**
     * 画像がアップロードされているパス
     * @type {string}
     * @memberof PageSettingAboutJobDto
     */
    imagePath?: string;
}

/**
 * Check if a given object implements the PageSettingAboutJobDto interface.
 */
export function instanceOfPageSettingAboutJobDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageSettingAboutJobDtoFromJSON(json: any): PageSettingAboutJobDto {
    return PageSettingAboutJobDtoFromJSONTyped(json, false);
}

export function PageSettingAboutJobDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageSettingAboutJobDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shok3Cd': !exists(json, 'shok3Cd') ? undefined : json['shok3Cd'],
        'dispName': !exists(json, 'dispName') ? undefined : json['dispName'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'imagePath': !exists(json, 'imagePath') ? undefined : json['imagePath'],
    };
}

export function PageSettingAboutJobDtoToJSON(value?: PageSettingAboutJobDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shok3Cd': value.shok3Cd,
        'dispName': value.dispName,
        'text': value.text,
        'imagePath': value.imagePath,
    };
}

