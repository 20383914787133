/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PageSettingProcessDto
 */
export interface PageSettingProcessDto {
    /**
     * 応募後のプロセスタイトル
     * @type {string}
     * @memberof PageSettingProcessDto
     */
    title?: string;
    /**
     * 応募後のプロセス本文 改行コードが含まれていれば取り除く
     * @type {string}
     * @memberof PageSettingProcessDto
     */
    body?: string;
}

/**
 * Check if a given object implements the PageSettingProcessDto interface.
 */
export function instanceOfPageSettingProcessDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageSettingProcessDtoFromJSON(json: any): PageSettingProcessDto {
    return PageSettingProcessDtoFromJSONTyped(json, false);
}

export function PageSettingProcessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageSettingProcessDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'body': !exists(json, 'body') ? undefined : json['body'],
    };
}

export function PageSettingProcessDtoToJSON(value?: PageSettingProcessDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'body': value.body,
    };
}

