import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import MasterDataContext from 'context/saiyouPageEditing/MasterDataContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import {
  useOccupationCodeValidationRules,
  useOccupationDescriptionValidationRules,
  useOccupationNameValidationRules
} from 'validation/rules/saiyouPageEditing';
import GroupingSelectBox from '../../../../../common/SelectBox/GroupingSelectBox';
import TextField from '../../../../../common/TextField';
import ImageUploadEntry from '../../../../ImageUploader/ImageUploadEntry';
import FormDivider from '../../shared/FormDivider';
import ReorderBlockHeader from '../../shared/ReorderBlockHeader';
import TabContentFormWrap from '../../shared/TabContentFormWrap';

type Props = {
  index: number;
  canSwapUp?: boolean;
  canSwapDown?: boolean;
  canRemove?: boolean;
  onSwapUp?: () => void;
  onSwapDown?: () => void;
  onRemove?: () => void;
};

/**
 * 「お仕事紹介」の並び替え可能ブロックを返す
 * @param {number} index 表示順
 * @param {boolean | undefined} canSwapUp 上要素と交換可能であるか
 * @param {boolean | undefined} canSwapDown 下要素と交換可能であるか
 * @param {boolean | undefined} canRemove 削除可能であるか
 * @param {Function | undefined} onSwapUp 上要素との交換時に実行される関数
 * @param {Function | undefined} onSwapDown 下要素との交換時に実行される関数
 * @param {Function | undefined} onRemove 削除時に実行される関数
 * @returns 「お仕事紹介」の並び替え可能ブロックを返す
 */
const JobIntroReorderBlock: FC<Props> = ({
  index,
  canSwapUp = false,
  canSwapDown = false,
  canRemove = false,
  onSwapUp,
  onSwapDown,
  onRemove
}) => {
  const { control, watch } = useFormContext<FormData>();
  const occupationCode = watch(`jobIntroduction.occupations.${index}.code`);
  const occupationName = watch(`jobIntroduction.occupations.${index}.name`);
  const occupationDescription = watch(
    `jobIntroduction.occupations.${index}.description`
  );
  const occupationImagePath = watch(
    `jobIntroduction.occupations.${index}.imagePath`
  );

  const { onChangeValue } = useContext(EditSaiyouPageContext);
  const { occupations } = useContext(MasterDataContext);

  const codeRules = useOccupationCodeValidationRules();
  const nameRules = useOccupationNameValidationRules();
  const descriptionRules = useOccupationDescriptionValidationRules();

  return (
    <TabContentFormWrap>
      <ReorderBlockHeader
        title={`職種${index + 1}`}
        canSwapUp={canSwapUp}
        canSwapDown={canSwapDown}
        canRemove={canRemove}
        onSwapUp={onSwapUp}
        onSwapDown={onSwapDown}
        onRemove={onRemove}
      />
      <Controller
        name={`jobIntroduction.occupations.${index}.code`}
        control={control}
        rules={{ ...codeRules, onChange: onChangeValue }}
        render={({ field: { onBlur, onChange }, formState: { errors } }) => (
          <GroupingSelectBox
            label="職種選択"
            values={occupationCode}
            placeholder="職種を選択してください"
            options={occupations}
            helperText={
              errors.jobIntroduction?.occupations?.[index]?.code?.message
            }
            isRequired
            isError={!!errors.jobIntroduction?.occupations?.[index]?.code}
            onBlur={onBlur}
            onChange={(items) =>
              onChange(items.length > 0 ? items[0] : undefined)
            }
          />
        )}
      />
      <Controller
        name={`jobIntroduction.occupations.${index}.name`}
        control={control}
        rules={{ ...nameRules, onChange: onChangeValue }}
        render={({ field: { onBlur, onChange }, formState: { errors } }) => (
          <TextField
            label="表示用職種名"
            value={occupationName}
            placeholder="採用コンサルタント(営業)"
            maxLength={100}
            helperText={
              errors.jobIntroduction?.occupations?.[index]?.name?.type !==
              'maxLength'
                ? errors.jobIntroduction?.occupations?.[index]?.name?.message
                : ''
            }
            hasLengthHelperText
            isRequired
            isError={!!errors.jobIntroduction?.occupations?.[index]?.name}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name={`jobIntroduction.occupations.${index}.description`}
        control={control}
        rules={{ ...descriptionRules, onChange: onChangeValue }}
        render={({ field: { onBlur, onChange }, formState: { errors } }) => (
          <TextField
            label="職種紹介文"
            value={occupationDescription}
            placeholder="アルバイト・パート、派遣、正社員まで雇用形態を問わず、人材募集を行っている企業に対して、当社の求人メディア「バイトル」「はたらこねっと」「バイトルNEXT」、DX商材の「コボット」で、人材採用支援から定着・活躍の支援まで、人材に関する課題解決に向けたコンサルティングを行う仕事です。"
            maxLength={100}
            rows={8}
            helperText={
              errors.jobIntroduction?.occupations?.[index]?.description
                ?.type !== 'maxLength'
                ? errors.jobIntroduction?.occupations?.[index]?.description
                    ?.message
                : ''
            }
            hasLengthHelperText
            isRequired
            isError={
              !!errors.jobIntroduction?.occupations?.[index]?.description
            }
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name={`jobIntroduction.occupations.${index}.imagePath`}
        control={control}
        rules={{ onChange: onChangeValue }}
        render={({ field: { onChange } }) => (
          <ImageUploadEntry
            uploadedImagePath={occupationImagePath}
            onUploadImage={onChange}
            onClearImage={() => onChange()}
          />
        )}
      />
      <FormDivider />
    </TabContentFormWrap>
  );
};

export default JobIntroReorderBlock;
