import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import ContentAcc from '../../../../../common/ContentAcc/ContentAcc';
import FormDivider from '../../shared/FormDivider';
import TabContentFormWrap from '../../shared/TabContentFormWrap';
import TabContentInner from '../../shared/TabContentInner';
import TabContentText from '../../shared/TabContentText';
import VisibilityToggleButton from '../../shared/VisibilityToggleButton';
import AfterProcessReorderBlocks from './AfterProcessReorderBlocks';

/**
 * 「応募後の流れ」アコーディオンを返す
 * @returns 「応募後の流れ」アコーディオン
 */
const AfterProcessAccordion: FC = () => {
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext<FormData>();

  const isVisible = watch('afterProcess.isVisible');

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('afterProcess');

  return (
    <ContentAcc
      ref={accordionRef}
      title="応募後の流れ"
      dispCrown
      isExpanded={isExpanded}
      isError={
        errors.afterProcess && Object.keys(errors.afterProcess).length > 0
      }
      onChange={(value) => onChange?.('afterProcess', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="具体的な採用プロセスを設定を追加すると応募者が今後の流れをよりイメージしやすくなり、応募に繋がりやすくなります。" />
        <TabContentFormWrap>
          <Controller
            name="afterProcess.isVisible"
            control={control}
            rules={{ onChange: onChangeValue }}
            render={({ field: { value, onChange } }) => (
              <VisibilityToggleButton isVisible={value} onChange={onChange} />
            )}
          />
          <FormDivider />
        </TabContentFormWrap>
        <AfterProcessReorderBlocks minLength={isVisible ? 1 : 0} />
      </TabContentInner>
    </ContentAcc>
  );
};

export default AfterProcessAccordion;
