import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import {
  useAfterProcessContentValidationRules,
  useAfterProcessTitleValidationRules
} from 'validation/rules/saiyouPageEditing';
import TextField from '../../../../../common/TextField';
import TabContentFormWrap from '../../shared/TabContentFormWrap';
import ReorderBlockHeader from '../../shared/ReorderBlockHeader';
import FormDivider from '../../shared/FormDivider';

type Props = {
  index: number;
  canSwapUp?: boolean;
  canSwapDown?: boolean;
  canRemove?: boolean;
  onSwapUp?: () => void;
  onSwapDown?: () => void;
  onRemove?: () => void;
};

/**
 * 「応募後の流れ」の並び替え可能ブロックを返す
 * @param {number} index 表示順
 * @param {boolean | undefined} canSwapUp 上要素と交換可能であるか
 * @param {boolean | undefined} canSwapDown 下要素と交換可能であるか
 * @param {boolean | undefined} canRemove 削除可能であるか
 * @param {Function | undefined} onSwapUp 上要素との交換時に実行される関数
 * @param {Function | undefined} onSwapDown 下要素との交換時に実行される関数
 * @param {Function | undefined} onRemove 削除時に実行される関数
 * @returns 「応募後の流れ」の並び替え可能ブロックを返す
 */
const AfterProcessReorderBlock: FC<Props> = ({
  index,
  canSwapUp = false,
  canSwapDown = false,
  canRemove = false,
  onSwapUp,
  onSwapDown,
  onRemove
}) => {
  const { control, watch } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const titleRules = useAfterProcessTitleValidationRules();
  const contentRules = useAfterProcessContentValidationRules();

  const title = watch(`afterProcess.processList.${index}.title`);
  const content = watch(`afterProcess.processList.${index}.content`);

  return (
    <TabContentFormWrap>
      <ReorderBlockHeader
        title={`プロセス${index + 1}`}
        canSwapUp={canSwapUp}
        canSwapDown={canSwapDown}
        canRemove={canRemove}
        onSwapUp={onSwapUp}
        onSwapDown={onSwapDown}
        onRemove={onRemove}
      />
      <Controller
        name={`afterProcess.processList.${index}.title`}
        control={control}
        rules={{ ...titleRules, onChange: onChangeValue }}
        render={({ field: { onBlur, onChange }, formState: { errors } }) => (
          <TextField
            label="タイトル"
            value={title}
            placeholder="求人に応募する"
            maxLength={20}
            helperText={
              errors.afterProcess?.processList?.[index]?.title?.type !==
              'maxLength'
                ? errors.afterProcess?.processList?.[index]?.title?.message
                : ''
            }
            hasLengthHelperText
            isRequired
            isError={!!errors.afterProcess?.processList?.[index]?.title}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name={`afterProcess.processList.${index}.content`}
        control={control}
        rules={{ ...contentRules, onChange: onChangeValue }}
        render={({ field: { onBlur, onChange }, formState: { errors } }) => (
          <TextField
            label="内容"
            value={content}
            placeholder="働きたい求人を選んで応募します。履歴書や職務経歴書のご準備をお願いします。"
            maxLength={100}
            rows={8}
            helperText={
              errors.afterProcess?.processList?.[index]?.content?.type !==
              'maxLength'
                ? errors.afterProcess?.processList?.[index]?.content?.message
                : ''
            }
            hasLengthHelperText
            isRequired
            isError={!!errors.afterProcess?.processList?.[index]?.content}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      />
      <FormDivider />
    </TabContentFormWrap>
  );
};

export default AfterProcessReorderBlock;
