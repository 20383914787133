/**
 * 左ペイン用アコーディオンのユニークコード
 */
export type AccordionCode =
  | 'design'
  | 'topImage'
  | 'corpIntro'
  | 'jobIntro'
  | 'staffInterview'
  | 'jobPoint'
  | 'afterProcess'
  | 'message'
  | 'faq'
  | 'corpName'
  | 'corpLogo'
  | 'homePage'
  | 'sns'
  | 'corpAbout'
  | 'topPageVisibility'
  | 'siteTitle'
  | 'brand'
  | 'target'
  | 'siteDescription';

/**
 * 左ペイン用アコーディオンのユニークコードの一覧
 */
export const allAccordionCodes: AccordionCode[] = [
  'design',
  'topImage',
  'corpIntro',
  'jobIntro',
  'staffInterview',
  'jobPoint',
  'afterProcess',
  'message',
  'faq',
  'corpName',
  'corpLogo',
  'homePage',
  'sns',
  'corpAbout',
  'topPageVisibility',
  'siteTitle',
  'brand',
  'target',
  'siteDescription'
];
