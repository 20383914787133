import { FC } from 'react';
import EmploymentStatusSelectBox from '../../../SharedFields/EmploymentStatusSelectBox';

/**
 * ターゲット(雇用形態)の選択フィールドを返す
 * @returns ターゲットの選択フィールド
 */
const EmploymentStatusField: FC = () => {
  return (
    <div className="input-content-wrap">
      <div className="input-content-title-wrap">
        <span className="input-content-title">ターゲット</span>
        <span className="input-content-title-sub">
          応募後の流れ、サイトタイトルやSEOに関する設定に使用するターゲットを選択してください。
        </span>
      </div>
      <EmploymentStatusSelectBox
        placeholder="選択してください"
        width={400}
        style={{ marginTop: '-10px' }}
      />
    </div>
  );
};

export default EmploymentStatusField;
