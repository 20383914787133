import { useEffect, useState } from 'react';
import { getBaitoruMasterData } from 'logic/clientWrapper/public';
import { EmploymentStatus } from 'types/saiyouPageEditing/EmploymentStatus';
import {
  CategorizedLabelAndValue,
  LabelAndValue
} from 'types/saiyouPageEditing/LabelAndValue';

/**
 * バイトルCoreマスターデータHooks
 * @returns バイトルCoreマスターデータ
 */
const useMasterData = (): {
  employmentStatuses: LabelAndValue[];
  occupations: CategorizedLabelAndValue[];
  features: LabelAndValue[];
} => {
  const [employmentStatuses, setEmploymentStatuses] = useState<LabelAndValue[]>(
    []
  );
  const [occupations, setOccupations] = useState<CategorizedLabelAndValue[]>(
    []
  );
  const [features, setFeatures] = useState<LabelAndValue[]>([]);

  useEffect(() => {
    const getMasterDataAsync = async () => {
      const response = await getBaitoruMasterData([
        'Koyokeitai',
        'Shokushu',
        'Tokucho'
      ]);

      if (!response) return;

      setEmploymentStatuses(
        response.koyokeitaiList?.map((src) => ({
          label: src.name || '',
          value: (src.code as EmploymentStatus) || ''
        })) || []
      );
      setOccupations(
        response.shokushuList?.flatMap(
          (shok1) =>
            shok1.shok2?.flatMap(
              (shok2) =>
                shok2.shok3?.map((shok3) => ({
                  label: shok3.name || '',
                  value: shok3.shok3Code || '',
                  category: shok1.name || ''
                })) || []
            ) || []
        ) || []
      );
      // TODO: 特徴はあとで
      setFeatures(
        response.tokuchoList?.map((src) => ({ label: '', value: '' })) || []
      );
    };

    void getMasterDataAsync();
  }, []);

  return { employmentStatuses, occupations, features };
};

export default useMasterData;
