import {
  MAX_URL_LENGTH,
  urlFormatErrorMsg
} from 'validation/rules/shared/consts';
import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { FormData } from 'types/saiyouPageEditing/FormData';

type Rules = RegisterOptions<FormData, 'sns.twitterUrl'>;

/**
 * LINEのURLのバリデーションルールを返す
 * @returns {Rules} バリデーションルール
 */
export const useLINEUrlValidationRules = (): Rules => {
  const propertyName = 'LINEのURL';

  return useMemo(
    () => ({
      validate: {
        validUrl: (value) => {
          if (!value) return undefined;

          return (value.startsWith('https://line.me/') ||
            value.startsWith('https://lin.ee/')) &&
            value.length <= MAX_URL_LENGTH
            ? undefined
            : urlFormatErrorMsg(propertyName);
        }
      }
    }),
    []
  );
};
